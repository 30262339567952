<template>
<v-card flat class="mt-5">
    <v-card-text>
        <div id="app">
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="libelle_ref" label="Reference de Souscription *" dense outlined required>
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-btn color="#3AA082" dark @click="verification_reference()">
                        Recherche
                        <v-icon right dark>
                            mdi-file-search
                        </v-icon>
                    </v-btn>

                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn color="#3AA082" dark @click="verification_reference()">
                        Visualisation Graphe
                        <v-icon right dark>
                            mdi-chart-bar-stacked
                        </v-icon>
                    </v-btn>

                </v-col>
                <v-col>
                    <v-btn color="#3AA082" dark @click="verification_reference()">
                        Visualisation Tableau 
                        <v-icon right dark>
                            mdi-table-large
                        </v-icon>
                    </v-btn>

                </v-col>

            </v-row>
            <v-col>
                <v-card color="#385F73" dark>
                    <v-card-title class="text-h5">
                        {{ text_reference }}
                    </v-card-title>

                    <v-card-subtitle>{{ text_indicateur }}</v-card-subtitle>

                </v-card>
            </v-col>
            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>

            <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>LITSE DES COLLECTE</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>

                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>

                                            <v-textarea v-model="libelle_retenir" label="QUE RETENIR ?*" dense outlined required></v-textarea>
                                            <v-textarea v-model="libelle_recommandation" label="RECOMMANDATION *" dense outlined required></v-textarea>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog=false">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.validation`]="{ item }">
                    <v-chip :color="getColor(item.validation)" dark>
                        {{ gettext(item.validation) }}
                    </v-chip>
                </template>
                <template v-slot:[`item.indicateurs`]="{ item }">
                    {{ item.indicateurs.code }} - {{ item.indicateurs.libelle }}
                </template>

                <template v-slot:[`item.souscriptions`]="{ item }">
                    {{ item.souscriptions.indicateurodd.libelle }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon large class="mr-2" :color="getColor(item.validation)" @click="editItem(item)">
                        {{ geticones(item.validation) }}
                    </v-icon>
                </template>

            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import api from '../../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbar: false,
        snackbars: false,
        snackbartest: false,
        text: '',
        textmessage: '',
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '5%'
            },
            {
                text: 'ID',
                value: 'id',
                divider: true,
                width: '5%'
            },
            {
                text: 'validation ',
                value: 'validation',
                divider: true,
                width: '5%'
            },
            {
                text: 'ANNEE',
                value: 'annee_collecte',
                divider: true,
                width: '10%'
            },
            // { text: 'Souscription', value: 'souscriptions' ,divider: true,width: '10%'},

        ],
        formTitle: 'Formulaire de validation',
        list: [],
        listindicateur: [],
        selectindicateur: '',
        listindicateur2: [],
        selectindicateur2: '',

        libelle_ref: '',
        libelle_retenir: '',
        libelle_recommandation: '',
        text_indicateur: '',
        text_reference: '',
        idcollecte: '',
        idsouscription: '',
        etats: '',

        valider: '0',
        id: '0',
        valid: true,

        editedIndex: -1,
        textetat: false,
        dateFormatted: null,
        messages: '',

        recuperer: {
            icones: '',
            etats: '',
            textrecup: ''

        },

    }),

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },

    watch: {
        date(val) {
            this.dateFormatted = this.formatDate(this.date)
        },
    },

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
            let fd = new FormData();
            fd.append('code', this.libelle_ref);
            const data = await api.createUpdatedata('info-collecte-data/all-only-info-collecte', fd);
            this.list = data.list;

            //  const data = await api.readAll('souscription/all-request-collecte');
            //  this.list = data.list;
        },

        readAllRecherche: async function () {
            const data = await api.readAll('parametre/all-modalite-odd/' + this.selectindicateur2);
            this.list = data.list;
        },

        readAllRecherche_souscription: async function () {
            let fd = new FormData();
            fd.append('code', this.libelle_ref);
            const data = await api.createUpdatedata('info-collecte-data/all-only-info-collecte', fd);
            this.list = data.list;
            console.log(this.list);
            // const data = await api.readAll('souscription/all-request-collecte/'+this.libelle_ref);
            //console.log(data.status);
            if (data.status == 200) {
                this.text_reference = "Réference du projet : " + this.libelle_ref;
                this.list = data.list;
            } else {
                this.text_reference = "";
                this.list = [];
                this.textmessage = data.message;
                this.snackbartest = {
                    color: "error",
                    icon: "mdi-alert-circle-outline",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Erreur",
                    text: this.textmessage,
                    visible: true
                };
            }

        },

        verification_reference() {
            if (this.libelle_ref == '') {
                // this.snackbartest=true;
                this.textmessage = "Veuiillez inserer la réference de souscription";

                this.snackbartest = {
                    color: "error",
                    icon: "mdi-alert-circle-outline",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Erreur",
                    text: this.textmessage,
                    visible: true
                };
                return;
            }
            this.readAllRecherche_souscription();
        },

        getColor(item) {
            if (item == 0) return 'red'
            else if (item == 1) return 'green'
            else return 'green'
        },

        gettext(item) {
            if (item == 0) return 'Non'
            else if (item == 1) return 'Oui'
            else return 'Non'
        },

        geticones(item) {
            if (item == 0) return 'mdi-database-lock'
            else if (item == 1) return 'mdi-database-check'
            else return 'mdi-database-lock'
        },

        readIndicateurAll: async function () {
            const data = await api.readAll('indicateur-odd/all');
            this.listindicateur = data.list;
            this.listindicateur2 = data.list;

        },

        fuseSearch() {
            this.readAllRecherche();
        },

        createUpdatedata: async function () {
            if (this.etats == '0') {
                this.etats = '1';
            } else {
                this.etats = '0';
            }
            let fd = new FormData();
            fd.append('id_souscriptions', this.idsouscription);
            fd.append('id_collectes', this.idcollecte);
            fd.append('recommandation', this.libelle_recommandation);
            fd.append('retenir', this.libelle_retenir);
            fd.append('validation', this.etats);

            const res = await api.createUpdatedata('souscription/add-request-collecte-validation', fd);
            this.text = res.message;
            this.snackbars = true;

            this.clear();
            this.readAllRecherche_souscription();
        },
        initialize() {
            this.readAllRecherche();
        },

        nouveau() {
            this.libelle_recommandation = '';
            this.libelle_retenir = '';
        },
        Notifications(messages) {
            //this.multiLine true,
            this.snackbar = true;
            this.text = messages;
        },

        clear() {

            this.textetat = false;
            this.dialog = false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.libelle_retenir = "";
            this.libelle_recommandation = "";
            this.id = item.id;
            this.idcollecte = item.id;
            this.idsouscription = item.souscriptions.id;
            this.etats = item.validation;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false;
        },

        save() {
            if (this.libelle_recommandation == '') {
                this.text = "Veuillez Inserer le libellé de la recommandation"
                this.snackbar = true;
                return;
            }

            if (this.libelle_retenir == '') {
                this.text = "Veuillez Inserer le libellé retenir"
                this.snackbar = true;
                return;
            }

            this.createUpdatedata();

        },

        parcoure: function filtrerParID(obj) {
            if (obj.id_cibles == this.selectcible) {
                console.log(obj.libelle);
                return true;
            } else {

                return false;
            }
        },

    },
    mounted() {
        //this.readAll();
        // this.readIndicateurAll();
        //this.readindicateurAll();
    },

}
</script>
